/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import helper functions
import * as url from "helpers/url_helper";

// import Gateway
import { getData } from "utils/Gateway";

import { useTranslation } from "react-i18next";

const FaqSearchResult = ({ 
            searchQuery,
            setSearchQuery,

            selectedCategory,
            setSelectedCategory,
            selectedTagCategory,
            setSelectedTagCategory,

            resetQuery,
            setResetQuery         
            }) => {
    
    const { t } = useTranslation();

    // user information
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));


    // states for faq search result
    const [faqData, setFaqData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [resultQuery, setResultQuery] = useState('');
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [paginationCount, setPaginationCount] = useState(page);

    // const [categoryOptions, setCategoryOptions] = useState([]);
    // const [tagOptions, setTagsOptions] = useState([]);

    // const [selectedCategory, setSelectedCategory] = useState('');
    // const [selectedTag, setSelectedTag] = useState('');
    // const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');
    // const [selectedTagLabel, setSelectedTagLabel] = useState('');

    const limit = 20;

    

    // get request to API to fetch faq data
    const getFaqData = async () => {

        setIsLoading(true);
        setResultQuery('');

        // fetch data
        try {

            let requestUrl = url.API_BaseUrl + url.API_FAQ_SEARCH + `?token=${token}`;

            if(searchQuery.length > 2 && resetQuery === false){
                requestUrl += `&query=${searchQuery}`;
            }

            // add page
            requestUrl += `&page=${page}`;
            // add limit
            requestUrl += `&limit=${limit}`;

            console.log('selectedCategory',selectedCategory);

            console.log('selectedTag',selectedTagCategory);

            // add category
            if(selectedCategory && resetQuery === false){
                requestUrl += `&category=${selectedCategory}`;
            }

            // add tag
            if(selectedTagCategory && resetQuery === false){
                requestUrl += `&tagcategory=${selectedTagCategory}`;
            }


            console.log('requestUrl for FAQ List',requestUrl);

            const response = await getData(requestUrl);

            console.log('FAQ data response',response);

            if(response.status === true){
                setFaqData(response.data);
                setResultQuery(response.query);
                setTotalResultCount(response.totalcount);

                // set pagination count
                setPaginationCount(Math.ceil(response.totalcount / limit));
            }

            setIsLoading(false);

        } catch (error) {   
            console.log(error.message);
        }

    }

    

    // useeffect to fetch data on change of searchQuery or resetQuery
    useEffect(() => {

        // fetch data
        if(searchQuery.length > 2 || resetQuery || selectedCategory || selectedTagCategory){
            getFaqData();
            setResetQuery(false); // after reload, set this false
        }  
        
        if(resetQuery){
            setSearchQuery('')
            //setSelectedTagLabel('');
            setSelectedCategory('');
            setSelectedTagCategory('');
        }
    
    }, [searchQuery, resetQuery, selectedCategory, selectedTagCategory]);

    // fetch if page changes
    useEffect(() => {
        // fetch data
        getFaqData();

    }, [page]);

    



    // by default load all faq data
    useEffect(() => {
        // fetch data
        getFaqData();
    }, []);

    return (
        <section className="faq-wrapper pt-4 pb-5">
            <div className="container">
                <div className="accordion-filter mb-4">
                    <div className="row align-items-center">
                        <div className="col-lg-3 mb-2 mb-lg-0">
                            <p>{t('Found')} {' '} {totalResultCount ? totalResultCount : faqData.length} {' '} {t('result')} {' '} 
                                {resultQuery && resultQuery.length > 2 ? `- ${t('searching')} "${resultQuery}"` : ''} 
                                
                            </p>
                        

                        </div>
                        <div className="col-lg-9">
                            <div className="d-flex flex-column flex-md-row gap-2 justify-content-lg-end">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="faqs-group">
                    {isLoading ? (
                        <div className="faqs px-4 py-3 mb-2 d-flex flex-wrap align-items-center justify-content-between gap-3">
                            <h3 className="placeholder-glow w-100">
                                <span className="placeholder col-6"></span>
                            </h3>
                            <ul className="d-flex flex-column gap-2 w-100">
                                {[...Array(5)].map((_, index) => (
                                    <li key={index}>
                                        <div className="placeholder-glow">
                                            <span className="placeholder col-12"></span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                       
                        faqData.length > 0 ? faqData.map((faq, index) => (
                        <div className="faqs px-4 py-3 mb-2 d-flex flex-wrap align-items-center justify-content-between gap-3" key={index}>
                            <div className="faq_left_info">
                                <h6 className="tex-gray fs-sm mb-2"> {faq.categorystring != null ? t("Catagory")+" : " : ''} {faq.categorystring}</h6>

                                <h4 className="fw-bold mb-3">{faq.title}</h4>
                                <ul className="tags d-flex align-items-center flex-wrap gap-2">                                     
                                    <li className="fw-bold">{faq.tagcategorystring != null ? t("Tags")+" : " : ''}</li>
                                    {faq.tagcategorystring &&
                                        faq.tagcategorystring.split(',').map((tag, index) => (
                                            <li key={index} className="tag px-3 py-2 rounded-8 bg-white">{tag.trim()}</li>
                                        ))
                                    }
                                    
                                </ul>
                            </div>
                            <div className="faq_details_btn">
                                <Link to={`/faq/details/${faq.id}`} className="d-flex align-items-center justify-content-between gap-3 basic_btn ylw_btn px-3">
                                    <span className="d-flex align-items-center justify-content-center material-icons-outlined">arrow_forward</span>
                                </Link>
                            </div>
                        </div>
                        )) : (
                            <div className="faqs px-4 py-3 mb-2 d-flex flex-wrap align-items-center justify-content-between gap-3">
                                <div className="faq_left_info">
                                    <h4 className="fw-bold mb-3">No result found</h4>
                                </div>
                            </div>
                        )
                     
                    )}
                </div>

                {/* pagination */}
                <ul className="pagination d-flex align-items-center justify-content-center gap-3 mt-4">
                    
                    <li className={`prev ${page > 1 ? '' : 'disabled'}`}>
                        <Link 
                            to="#" 
                            className="d-flex align-items-center justify-content-center" 
                            onClick={() => page > 1 && setPage(page - 1)}
                        >
                            <span className="d-block material-icons-outlined">chevron_left</span>
                        </Link>
                    </li>
                    
                    {[...Array(paginationCount)].map((_, index) => (

                        <li key={index} className={`page ${index + 1 == page ? 'active' : ''}`}>
                            <Link to="#" className="d-flex align-items-center justify-content-center" onClick={() => setPage(index + 1)}>
                                {index + 1}
                            </Link>
                        </li>
                        
                    ))}
                    
                    <li className={`next ${faqData.length === limit ? '' : 'disabled'}`}>
                        <Link 
                            to="#" 
                            className="d-flex align-items-center justify-content-center" 
                            onClick={() => faqData.length === limit && setPage(page + 1)}
                        >
                            <span className="d-block material-icons-outlined">chevron_right</span>
                        </Link>
                    </li>

                </ul>
            </div>
        </section>
    );
};

export default FaqSearchResult;