import React, { useState, useEffect } from 'react';
import AskQuestionModal from 'components/FaqComponents/AskQuestion/AskQuestion';
import { Link } from 'react-router-dom';

// import helper functions
import * as url from "helpers/url_helper";

// import Gateway
import { getData } from "utils/Gateway";

import { useTranslation } from "react-i18next";

const FaqSearch = ({
                    setSearchQuery,
                    setResetQuery,
                    setSelectedCategory,
                    setSelectedTagCategory

}) => {

    const { t } = useTranslation();

    // user information
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [tagCategoryOptions, setTagCategoryOptions] = useState([]);

    
    const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');
    const [selectedTagCategoryLabel, setSelectedTagCategoryLabel] = useState('');

    const [searchFaq, setSearchFaq] = useState('');

    // function to validate search query
    const handleSearch = (e) => {
        e.preventDefault();
        const searchQuery = e.target[0].value;
        if (searchQuery.length > 2) {
            setSearchQuery(searchQuery);
        }
    };

    

    
        
        // function to get categories details from API
        const getCategories = async (slug = 'CAT_FAQ') => {
            const requestURL =
                url.API_BaseUrl + url.API_FAQ_FILTER_CATEGORIES + `?token=${token}&pslug=${slug}`;
    
            try {
                const response = await getData(requestURL);
    
                console.log('Categories response',response);
    
                if (response.status) {
                    if (
                        response.data.length === 0 ||
                        response.data == null ||
                        response.data === undefined
                    ) {
                        setCategoryOptions([]);
                    } else {
                        setCategoryOptions(response.data);
                    }
                } else {
                    setCategoryOptions([]);
                }
            } catch (error) {
                console.log(error.message);
            }
        };

        // function to get categories details from API
        const getTagCategories = async (slug = 'CAT_FAQ_TAG') => {
            const requestURL =
                url.API_BaseUrl + url.API_FAQ_FILTER_CATEGORIES + `?token=${token}&pslug=${slug}`;
    
            try {
                const response = await getData(requestURL);
    
                console.log('Categories response',response);
    
                if (response.status) {
                    if (
                        response.data.length === 0 ||
                        response.data == null ||
                        response.data === undefined
                    ) {
                        setTagCategoryOptions([]);
                    } else {
                        setTagCategoryOptions(response.data);
                    }
                } else {
                    setTagCategoryOptions([]);
                }
            } catch (error) {
                console.log(error.message);
            }
        };
    
    
        const categorySelectHandler = (id, label = null) => {
    
            console.log('Selected Category',id);
    
            setSelectedCategory(id);
            setSelectedCategoryLabel(label);
    
            // also close the dropdown
            document.querySelector('.catdropdownlabel').classList.remove('show');
            document.querySelector('.catdropdown').classList.remove('show');
        }
    
        const tagCategorySelectHandler = (id, label = null) => {
    
            console.log('Selected Category',id);
    
            setSelectedTagCategory(id);
            setSelectedTagCategoryLabel(label);
    
            // also close the dropdown
            document.querySelector('.tagdropdownlabel').classList.remove('show');
            document.querySelector('.tagdropdown').classList.remove('show');
        }


        useEffect(() => {

            getCategories();
            getTagCategories();

        }, []);
          

    return (
        <>
        <section className="faq-header pt-4">
            <div className="container">
                <div className="d-flex flex-wrap faq_search_container gap-3 justify-content-between">
                    <div className="left_search_column">
                        <form action="" className="position-relative mb-4" onSubmit={handleSearch}>
                            <input 
                                type="text" 
                                className="form-control rounded-0 shadow-none" 
                                placeholder={t('Search for your question')}
                                value={searchFaq}
                                onChange={(e) => setSearchFaq(e.target.value)}
                            />
                            <button type="submit" className="position-absolute top-50 translate-middle-y">
                                <span className="material-icons-outlined">search</span>
                            </button>
                        </form>
                    </div>
                    <div className="right_column accordion-filter d-flex justify-content-end mb-4 mb-lg-0 gap-2 flex-wrap">
                        <div className="dropdown_wrap d-flex gap-2">
                            <div className="dropdown parent">
                                <button className="catdropdownlabel dropdown-toggle d-flex justify-content-between w-100 w-md-auto shadow-none" type="button" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
                                    { selectedCategoryLabel 
                                        ? (selectedCategoryLabel.length > 15 
                                            ? selectedCategoryLabel.substring(0, 15) + '..' 
                                            : selectedCategoryLabel) 
                                        : 'Select by Subsistema' }
                                </button>
                                <ul className="catdropdown dropdown-menu w-100 w-md-auto border-0 rounded-0">
                                        
                                    {/* run a map on categoryOptions, if there is category.children the show submenu else show li*/}

                                    {categoryOptions && categoryOptions.length > 0 ? 
                                        categoryOptions.map((category, index) => ( 
                                        
                                            category.children && category.children.length > 0 ? (
                                                <li key={index}>
                                                    <div className="dropdown children dropend">
                                                        <Link type="button" to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {category.name}
                                                        </Link>
                                                        <ul className="dropdown-menu w-100 w-md-auto border-0 rounded-0">
                                                            {category.children.map((child, childIndex) => (
                                                                
                                                                    <li key={childIndex}>
                                                                        <Link to="#" 
                                                                            className="dropdown-item"
                                                                            onClick={() => categorySelectHandler(child.id, child.name)}    
                                                                        >
                                                                            {child.name}
                                                                        </Link>
                                                                    </li>
                                                               

                                                            ))}
                                                        </ul>
                                                    </div>
                                                </li>
                                            ) : (
                                                <li key={index}>
                                                    <Link to="#" 
                                                        className="dropdown-item"
                                                        onClick={() => categorySelectHandler(category.id, category.name)}    
                                                    >
                                                        {category.name}
                                                    </Link>
                                                </li>
                                            )
                                        )) : 
                                        ('')
                                    }
                                    
                                    
                                </ul>
                            </div>


                            <div className="dropdown parent">
                                <button className="tagdropdownlabel dropdown-toggle d-flex justify-content-between w-100 w-md-auto shadow-none" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                    { selectedTagCategoryLabel 
                                        ? (selectedTagCategoryLabel.length > 15 
                                            ? selectedTagCategoryLabel.substring(0, 15) + '..' 
                                            : selectedTagCategoryLabel) 
                                        : 'Select Label' }
                                </button>
                                <ul className="tagdropdown dropdown-menu w-100 w-md-auto border-0 rounded-0">
                                    
                                    {tagCategoryOptions && tagCategoryOptions.length > 0 ? 
                                        tagCategoryOptions.map((category, index) => ( 
                                        
                                            category.children && category.children.length > 0 ? (
                                                <li key={index}>
                                                    <div className="dropdown children dropend">
                                                        <Link type="button" to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {category.name}
                                                        </Link>
                                                        <ul className="dropdown-menu w-100 w-md-auto border-0 rounded-0">
                                                            {category.children.map((child, childIndex) => (
                                                                
                                                                    <li key={childIndex}>
                                                                        <Link to="#" 
                                                                            className="dropdown-item"
                                                                            onClick={() => tagCategorySelectHandler(child.id, child.name)}    
                                                                        >
                                                                            {child.name}
                                                                        </Link>
                                                                    </li>
                                                                //)

                                                            ))}
                                                        </ul>
                                                    </div>
                                                </li>
                                            ) : (
                                                <li key={index}>
                                                    <Link to="#" 
                                                        className="dropdown-item"
                                                        onClick={() => tagCategorySelectHandler(category.id, category.name)}    
                                                    >
                                                        {category.name}
                                                    </Link>
                                                </li>
                                            )
                                        )) : 
                                        ('')
                                    }
                                    
                                    
                                    
                                </ul>
                            </div>
                        </div>



                        <div className="btn_wrap d-flex gap-2">
                            <Link to="#" 
                                className="basic_btn ylw_btn w-auto px-3 refresh_btn" 
                                type="button"
                                onClick={() => {
                                        setResetQuery(true);
                                        setSearchFaq('');
                                        setSearchQuery('');
                                        setSelectedCategoryLabel('');
                                        setSelectedTagCategoryLabel('');
                                        setSelectedCategory('');
                                    }}
                                >
                                <span className="material-icons-outlined"> refresh </span>
                            </Link>
                            <Link to="#" 
                                className="basic_btn ylw_btn w-auto" 
                                type="button" 
                                data-bs-toggle="modal" 
                                data-bs-target="#askQuestion"
                            
                            >
                                {t('Ask Question')}
                            </Link>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>


        <AskQuestionModal />

        </>
    );
};




export default FaqSearch;

