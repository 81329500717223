/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import brochurePdf from "assets/CourseDetailsAssests/images/PDF.jpg";
import brochureDoc from "assets/CourseDetailsAssests/images/DOC.jpg";
import brochurePpt from "assets/CourseDetailsAssests/images/PPT.jpg";
import brochureXls from "assets/CourseDetailsAssests/images/XLS.jpg";

import SentModal from 'common/Modals/SentModal';

// import Gateway for API fetch call
import { postData, getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";


const FaqDetails = () => {

    // user information
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const params = useParams();

    const [faqDetails, setFaqDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [modalImage, setModalImage] = useState('');

    const [doubtDescription, setDoubtdescription] = useState('');
    const [doubtDescriptionError, setDoubtDescriptionError] = useState(false);
    const [doubtCount, setDoubtCount] = useState(0);
    const [sendingDoubt, setSendingDoubt] = useState(false);

    /* ------------- Language translation imports starts here ------------- */
    const { t, i18n } = useTranslation();

    // get faq details
    const getFaqDetails = async (id) => {

        setIsLoading(true);

        try {
            const requestURL = url.API_BaseUrl + url.API_FAQ_DETAILS + `?token=${token}&id=${id}`;

            console.log('FAQ req url',requestURL);

            const response = await getData(requestURL);

            console.log('FAQ details response',response);

            if(response.status === true){
                setFaqDetails(response.data);

                // set doubt count
                setDoubtCount(response.data.doubtcount);
            }

            setIsLoading(false);

            
        } catch (error) {
            console.log(error.message);
        }
    }

    // get image url as params and set it to modalImage state and open modal
    const handleImageModal = (url) => {
        setModalImage(url);

        const modal = new bootstrap.Modal(document.getElementById('imageModal'), {
            keyboard: false,
            backdrop: 'static'
        });

        modal.show();
    }

    // close image modal
    const handleClose = () => {
        setModalImage('');
    }

    // handle doubt submit, submit the description via post request to API send doubt
    // on success = true close the modal and show success message
    const handleDoubtSubmit = async() => {
        if(doubtDescription.length < 10){
            setDoubtDescriptionError(true);
        }else{
            setDoubtDescriptionError(false);

            setSendingDoubt(true);

            // doubt submit API call
            console.log('Doubt Description', doubtDescription);

            // API call to submit doubt
            try {
                    const requestUrl = url.API_BaseUrl + url.API_FAQ_DOUBT + `?token=${token}`;
                    //const requestUrl = url.API_BaseUrl + url.DEMO_POST + `?token=${token}`;
                    const data = {
                        message: doubtDescription,
                        faqid: params.id
                    };

                    console.log('data', data);

                    console.log('requestUrl', requestUrl);

                    const response = await postData(requestUrl, data);

                    console.log(response);

                    // close the modal
                    let doubtModal = document.querySelector("#askDoubt");
                    let modal = bootstrap.Modal.getInstance(doubtModal);
                    modal.hide();

                    // clear the doubt description
                    setDoubtdescription('');

                    setDoubtCount(parseInt(doubtCount, 10) + 1);

                    // on success
                    // show success message
                    let thanksModal = new bootstrap.Modal(
                    document.getElementById("sent_mdl")
                    );
                    thanksModal.show();    

                    setSendingDoubt(false);

                    if(response.status === true){
                                            
                    }

                } catch (error) {
                    console.log(error.message);
                }

            
        }
    }


    // useEffect from here
    useEffect(() => {
    getFaqDetails(params.id);
    }, [params.id]);

    return (
        <>
        {isLoading ? (
            <>
                <div className="placeholder-glow">
                    <h1 className="placeholder col-6"></h1>
                </div>

                <ul className="tags d-flex align-items-center flex-wrap gap-2 mb-3">
                    
                    <li className="tag px-3 py-2 rounded-8 placeholder-glow">
                        <span className="placeholder col-3"></span>
                    </li>
                    <li className="tag px-3 py-2 rounded-8 placeholder-glow">
                        <span className="placeholder col-3"></span>
                    </li>
                    <li className="tag px-3 py-2 rounded-8 placeholder-glow">
                        <span className="placeholder col-3"></span>
                    </li>
                </ul>
                <div className="placeholder-glow">
                    <p className="placeholder col-12"></p>
                    <p className="placeholder col-12"></p>
                    <p className="placeholder col-12"></p>
                </div>
                <Link to="#" className="basic_btn ylw_btn w-auto mt-4 placeholder-glow">
                    <span className="placeholder col-6"></span>
                </Link>
                
                {/* <div className="relate-to mt-5">
                    <h3 className="mb-3">{t('Related to')}:</h3>
                    
                    <h6 className="mb-2">{t('Post')}</h6>
                    <ul className="mb-3">
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                    </ul>
                    <h6 className="mb-2">{t('Experts')}</h6>
                    <ul>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                    </ul>

                    <h6 className="mb-2">{t('Courses')}</h6>
                    <ul>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                    </ul>
                </div> */}
            </>
        ) : (
        <>
            {/* Faq details */}
            <h1>{faqDetails.question}</h1>
            {faqDetails.tagcategorylabels && faqDetails.tagcategorylabels.length > 0 ? (
                <ul className="tags d-flex align-items-center flex-wrap gap-2 mb-3">
                    <li className="fw-bold d-flex align-items-center gap-1">
                        {t('Tags')} {' : '}
                    </li>
                    {faqDetails.tagcategorylabels && faqDetails.tagcategorylabels.map((tag, index) => (
                        <li key={index} className="tag px-3 py-2 rounded-8">{tag.label}</li>
                    ))}
                </ul>
            ) : ''}
            
            {faqDetails.categorylabels && faqDetails.categorylabels.length > 0 ? (
                <ul className="tags d-flex align-items-center flex-wrap gap-2 mb-3">
                    <li className="fw-bold d-flex align-items-center gap-1">
                        {t('Categories')} {' : '}
                    </li>
                    {faqDetails.categorylabels && faqDetails.categorylabels.map((categorylabel, index) => (
                        <li key={index} className="tag px-3 py-2 rounded-8">{categorylabel.label}</li>
                    ))}
                </ul>

                ):''}
            
            
            <p dangerouslySetInnerHTML={{ __html: faqDetails.answer }}></p>
            

            <Link to="#" 
                class="basic_btn ylw_btn w-auto mt-4"
                data-bs-toggle="modal"
                data-bs-target="#askDoubt"                                
                >
                Any Doubt

                {doubtCount > 0 && (
                    <span className="ms-2 d-flex align-items-center gap-1">
                        <span className="material-icons-outlined">comment</span>
                        {doubtCount}
                    </span>
                )}
                
            </Link>

            {faqDetails.uploads && faqDetails.uploads.length > 0 && (
                <h6 class="mb-3 mt-4">{t('Attach File')}</h6>            
            )}
            
            <div className="d-flex gap-2 flex-wrap">
                {faqDetails.uploads && faqDetails.uploads.map((file, index) => {
                    const extension = file.name.split('.').pop().toLowerCase();

                    // Video files
                    if (['mp4', 'webm', 'ogg'].includes(extension)) {
                        return (
                            <div key={index} className="bg-gray position-relative">
                                <figure className="h-100 w-100 position-relative">
                                    <video controls className="w-100" style={{ maxWidth: '500px' }}>
                                        <source src={file.url} type={`video/${extension}`} />
                                        Your browser does not support the video tag.
                                    </video>
                                </figure>
                            </div>
                        );
                    }

                    // image files
                    if (['png', 'jpg', 'jpeg'].includes(extension)) {
                        return (
                            <div key={index} className="bg-gray position-relative" style={{ maxWidth: '500px' }}>
                                <figure className="h-100 w-100 position-relative">
                                    <img src={file.url} 
                                       style={{ maxWidth:'250px' }} 
                                       alt={file.name.length > 20 
                                            ? `${file.name.substring(0,15)}...${extension}`
                                            : file.name}
                                        onClick={() => handleImageModal(file.url)}
                                    />
                                </figure>
                            </div>
                        );
                    }

                    // Other file types
                    return (
                        <div key={index} className="bg-gray position-relative">
                            <span className="d-flex align-items-center justify-content-center file-icon p-3 border-bottom">
                                <img 
                                    src={
                                        extension === 'pdf' ? brochurePdf :
                                        extension === 'doc' || extension === 'docx' ? brochureDoc :
                                        extension === 'ppt' || extension === 'pptx' ? brochurePpt :
                                        extension === 'xls' || extension === 'xlsx' ? brochureXls :
                                        brochureDoc
                                    } 
                                    alt={extension} 
                                    style={{ width: '100px' }}
                                />
                            </span>
                            <div className="doc_info d-flex align-items-center gap-2 justify-content-between px-3 py-2">
                                <div className="left_info">
                                    <p className="text-dark-primary fs-sm fw-regular">
                                        {file.name.length > 20 
                                            ? `${file.name.substring(0,15)}...${extension}`
                                            : file.name}
                                    </p>
                                    
                                </div>
                                <a href={file.url} download className="d-inline-block text-gray fs-md">
                                    <span className="material-icons-outlined">file_download</span>
                                </a>
                            </div>
                        </div>

                    );


                })}                
            </div>
        
            
            {/* <Link to="#" className="basic_btn ylw_btn w-auto mt-4">{t('Any Doubt')}</Link> */}

            
            
            {/* related Faqs */}
            <div className="relate-to mt-5">
                {(faqDetails.experts && faqDetails.experts.length > 0) ||
                (faqDetails.posts && faqDetails.posts.length > 0) ||
                (faqDetails.courses && faqDetails.courses.length > 0) ||
                (faqDetails.links && faqDetails.links.length > 0 ) ? (
                    <h3 className="mb-3">{t('Related to')}:</h3>
                ) : ''               
                
                }
                               
                
                <h6 className="mb-2">{faqDetails.experts && faqDetails.experts.length > 0 ? t('Champions') : ''}</h6>
                <ul>
                    {faqDetails.experts && faqDetails.experts.map((expert, index) => (
                        <li key={index}>
                            <Link to={`/champions/details/${expert.id}`} className="d-flex align-items-center gap-2" target="_blank">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline">{expert.label} - {expert.email}</span>
                            </Link>
                        </li>
                    ))}
                    
                </ul>


                
                <h6 className="mt-2 mb-2">{ faqDetails.posts && faqDetails.posts.length > 0 ? t('Post') : ''}</h6>
                <ul className="mb-3">
                    {faqDetails.posts && faqDetails.posts.map((post, index) => (
                        <li key={index}>
                            <Link to={`/blog/${post.slug}`} className="d-flex align-items-center gap-2" target="_blank">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline">{post.label}</span>
                            </Link>
                        </li>
                    ))}
                </ul>

                <h6 className="mt-2 mb-2">{ faqDetails.links && faqDetails.links.length > 0 ? t('External Links') : ''}</h6>
                <ul className="mb-3">                   
                    {faqDetails.links && faqDetails.links.map((link, index) => (
                        <li key={index}>
                            <a href={link.url} className="d-flex align-items-center gap-2" target="_blank">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline">{link.label}</span>
                            </a>
                        </li>
                    ))}
                </ul>

                <h6 className="mt-2 mb-2">{faqDetails.courses && faqDetails.courses.length > 0 ? t('Course') : ''}</h6>
                <ul className="mb-3">
                    {faqDetails.courses && faqDetails.courses.map((course, index) => (
                        <li key={index}>
                            <Link to="#" className="d-flex align-items-center gap-2" target="_blank">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline">{course.label}</span>
                            </Link>
                        </li>
                    ))}
                </ul>

                          
            </div>


            {/* image popup modal */}
            <div className="modal fade" id="imageModal" tabIndex="-1" aria-labelledby="imageModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header p-4 pb-0">
                            <h1 className="modal-title fs-5" id="imageModalLabel">Full Screen Image</h1>
                            <button type="button" className="btn-close m-0 shadow-none" 
                            data-bs-dismiss="modal" 
                            aria-label="Close" 
                            onClick={handleClose}></button>
                        </div>
                        <div className="modal-body p-4">
                            <img src={modalImage} alt="Full screen view" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>

            {/* any doubt modal */}
            <div className="modal fade" 
            id="askDoubt" 
            tabIndex="-1" 
            aria-labelledby="askDoubt" 
            aria-hidden="true" 
            data-bs-backdrop="static"
            >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header p-4 pb-0 d-flex">
                        <h1 className="modal-title fs-5 mb-0" id="askQuestionLabel">{t('Ask Doubt')}</h1>
                        <button type="button" className="btn-close m-0 shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="form-group mb-4">
                                <textarea name="description" id="description" 
                                className="form-control shadow-none" 
                                rows="5" 
                                value={doubtDescription}
                                onChange={(e) => setDoubtdescription(e.target.value)}
                                ></textarea>

                                {doubtDescriptionError && <p className="text-danger">Describe something more about the doubt. Atlest 20 characters</p>}

                            </div>
                            
                            <div className="action">
                                <button type="button" 
                                className="basic_btn ylw_btn w-auto"
                                onClick={handleDoubtSubmit}
                                >{t('Submit')}
                                    {sendingDoubt && (
                                    <div
                                        className="mx-2 spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        

        <SentModal />
        </>
        )}
        </>
    );
};

export default FaqDetails;